import React, { useState } from 'react';
import './Card.css';

const Card = ({ image, hoverImage, category, title, description, url }) => {
  // State to track whether the card is hovered
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      className="post-module"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="thumbnail">
        {/* Switch between the main image and the hover image based on the hover state */}
        <img src={isHovered ? hoverImage : image} alt={title} />
      </div>
      <div className="post-content">
        <div className="category">{category}</div>
        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>
      </div>
    </a>
  );
};

export default Card;