import React from "react";
import './ProfileCard.css'; // Import the CSS
import { Tab, Tabs, Box } from "@mui/material";
import Card from './Card/card'

//Images
import stick from '../images/stick.png'
import stickHover from '../images/stickHover.jpg'
import BTM from '../images/BTM.png'
import BTMHover from '../images/BTMHover.jpg'
import Raver from '../images/raver.png'
import RaverHover from '../images/raverHover.png'
import GroundBreaker from '../images/groundbreaker.png'
import GroundBreakerHover from '../images/groundbreakerHover.png'
import Chess from '../images/chess.png'
import ChessHover from '../images/chessHover.jpg'
import Randomify from '../images/randomify.png'
import RandomifyHover from '../images/randomifyHover.png'
import BodyCombat from '../images/bodycombat.jpg'
import BodyCombatHover from '../images/bodycombatHover.jpg'
import StickJam from '../images/stickJam.png'
import StickJamHover from '../images/stickJamHover.png'
import Clickaxe from '../images/clickaxe.png'
import ClickaxeHover from '../images/clickaxeHover.jpg'
import FinIce from '../images/fin-ice.png'
import FinIceHover from '../images/fin-iceHover.png'
import Gyro from '../images/gyro.png'
import GyroHover from '../images/gyroHover.png'
import Nino from '../images/nino.png'
import NinoHover from '../images/ninoHover.png'
import Monkeys from '../images/monkeys.png'
import MonkeysHover from '../images/monkeysHover.png'
import Slump from '../images/slump.png'
import SlumpHover from '../images/slumpHover.png'
import Clown from '../images/clown.png'
import ClownHover from '../images/clownHover.png'
import Magolf from '../images/magolf.png'
import MagolfHover from '../images/magolfHover.png'
import Life from '../images/life.png'
import LifeHover from '../images/lifeHover.png'
import Odders from '../images/odders.png'
import OddersHover from '../images/oddersHover.png'
import Jam from '../images/jam.png'
import JamHover from '../images/jamHover.png'
import SGD from '../images/sgd.png'
import SGDHover from '../images/sgdHover.png'

import CV from '../files/Javier-Camacho - CV.pdf'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faItchIo, faTwitter, faLinkedin, faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import Header from "./Header/header";

const WorkedOnData = [
    {
        image: stick,
        hoverImage: stickHover, // Hover image added
        category: "Cute Doggy Puzzle",
        description: "A cute puzzle game about a dog who loves looong sticks. - PC & Switch.",
        title: "Stick to the Plan",
        url: "https://store.steampowered.com/app/2334280/Stick_to_the_Plan/"
    },
    {
        image: BTM,
        hoverImage: BTMHover, // Hover image added
        category: "Graphic (mis)adventure",
        description: "Graphic (mis)adventure about a boy who becomes giant when hearing the word 'FUTURE'. - PC/Web.",
        title: "Bigger Than Me",
        url: "https://store.steampowered.com/app/1882690/Bigger_Than_Me/"
    },
    {
        image: Raver,
        hoverImage: RaverHover, // Hover image added
        category: "Friends vs Friends Spin-Off",
        description: `Short narrative videogame made to commission for Brainwash Gang, starring a character from their game "Friends VS Friends". -PC/Web.`,
        title: "Raver's Rumble",
        url: "https://deadpixeltales.itch.io/ravers-rumble"
    },
    {
        image: Randomify,
        hoverImage: RandomifyHover, // Hover image added
        category: "Unity Asset",
        description: "Randomify is a blazingly fast and easy-to-use weighted randomizer for Unity. - Unity Plugin.",
        title: "Randomify",
        url: "https://assetstore.unity.com/packages/templates/systems/randomify-fast-easy-weighted-randomizer-174978"
    },
    {
        image: Chess,
        hoverImage: ChessHover, // Hover image added
        category: "VR Chess",
        description: "The classic Chess game on Virtual Reality. Including multiplayer! - Oculus Quest, Pico.",
        title: "Chess Club",
        url: "https://www.oculus.com/experiences/quest/5353996901307344/"
    },
    {
        image: BodyCombat,
        hoverImage: BodyCombatHover, // Hover image added
        category: "VR Fitness",
        description: "A fitness VR game based on Les Mills Bodycombat. I worked on the HTC porting. - Oculus Quest, HTC, Pico.",
        title: "Les Mills Body Combat VR",
        url: "https://www.oculus.com/experiences/quest/5353996901307344/"
    }
];

const GameJamData = [
    {
        image: Clickaxe,
        hoverImage: ClickaxeHover, // Hover image added
        category: "Clicker Roguelite",
        description: "A clicker roguelite! The Sun has been stolen. The night has come. - PC/Web",
        title: "Clickaxe",
        url: "https://delunado.itch.io/clickaxe"
    },
    {
        image: StickJam,
        hoverImage: StickJamHover, // Hover image added
        category: "Cute Puzzle",
        description: "A cute puzzle game about a dog who loves looong sticks. Jam version. - PC/Switch.",
        title: "Stick to the plan [JAM]",
        url: "https://deadpixeltales.itch.io/stick-to-the-plan-jam"
    },
    {
        image: Gyro,
        hoverImage: GyroHover, // Hover image added
        category: "Physics Puzzle",
        description: "Physics-based puzzle game where you control the map, not the player! - PC/Web",
        title: "Gyroglyph",
        url: "https://delunado.itch.io/gyroglyph"
    },
    {
        image: Magolf,
        hoverImage: MagolfHover, // Hover image added
        category: "Magic Golf",
        description: "Magic & Golf in the guts of a colossus. - PC/Web.",
        title: "Magolf",
        url: "https://delunado.itch.io/magolf"
    },

    {
        image: GroundBreaker,
        hoverImage: GroundBreakerHover, // Hover image added
        category: "Incremental/Roguelite",
        description: "GroundBreaker is an incremental roguelite experience where you have to reach the end of a dark mine. - PC/Web.",
        title: "GroundBreaker",
        url: "https://delunado.itch.io/groundbreaker"
    },
    {
        image: Nino,
        hoverImage: NinoHover, // Hover image added
        category: "Turn-Based Shooter",
        description: "Turn-Based Twin-Stick Shooter where Nino must look for his grandfather. - PC/Web.",
        title: "Nino, your grandpa is nuts!",
        url: "https://eufrasi0.itch.io/nino"
    },
    {
        image: FinIce,
        hoverImage: FinIceHover, // Hover image added
        category: "Party Local Multiplayer",
        description: "2-Players Local Competitive Game about Tag and Penguins! - PC/Web",
        title: "Fin Ice",
        url: "https://delunado.itch.io/fin-ice"
    },
    {
        image: Life,
        hoverImage: LifeHover, // Hover image added
        category: "Sand Box",
        description: "Open-Source Sandbox Simulation made in Unity. - PC.",
        title: "Life in a Box",
        url: "https://delunado.itch.io/life-in-a-box"
    },
    {
        image: Monkeys,
        hoverImage: MonkeysHover, // Hover image added
        category: "Party Local Multiplayer",
        description: "2-Players Local Competitive Game about Monkeball! - PC/Web",
        title: "Fighting Monkeys Temple Club",
        url: "https://gekkothefool.itch.io/monkeys-temple"
    },
    {
        image: Slump,
        hoverImage: SlumpHover, // Hover image added
        category: "Casual Arcade",
        description: "This little slime from outer space is just having a relaxing day jumping and having some fun! - PC/Web",
        title: "Slump!",
        url: "https://delunado.itch.io/slump"
    },
    {
        image: Clown,
        hoverImage: ClownHover, // Hover image added
        category: "Casual Relaxing",
        description: "A relaxing game where you are a little clownfish who lives in an anemone. Recolect food in order to survive! - PC/Web.",
        title: "Clownbiosis",
        url: "https://delunado.itch.io/clownbiosis"
    }
];


const TalksAndWorkshops = [
    {
        image: Odders,
        hoverImage: OddersHover, // Hover image added
        category: "Talk",
        description: "A talk about my experience jumping from 2D indie games to VR industry. - Spanish.",
        title: "Saltando entre dimensiones: 2D a VR",
        url: "https://www.youtube.com/watch?v=uHDHh5ad1LA"
    },
    {
        image: Jam,
        hoverImage: JamHover, // Hover image added
        category: "Talk",
        description: "A handful of tips and tricks from my experience participating in Game Jams. - Spanish.",
        title: "Game Jams: ¿comer, dormir y... ganar?",
        url: "https://www.youtube.com/watch?v=E7I4RYhflY0"
    },
    {
        image: SGD,
        hoverImage: SGDHover, // Hover image added
        category: "Talk",
        description: "Around 18 talks about different parts of game development from both new and veterans from spanish gamedev industry. - Spanish.",
        title: "Spain Game Devs Talks",
        url: "https://www.youtube.com/@SpainGameDevs/videos"
    }
];


const ProfileCard = () => {

    const [actualTab, setActualTab] = useState("one");
    const [actualData, setActualData] = useState(GameJamData.concat(WorkedOnData).concat(TalksAndWorkshops));

    const changeTab = (tab) => {
        if (tab === "one") {
            setActualTab("one");
            setActualData(GameJamData.concat(WorkedOnData).concat(TalksAndWorkshops));
        }
        if (tab === "two") {
            setActualTab("two");
            setActualData(WorkedOnData);
        }
        if (tab === "three") {
            setActualTab("three");
            setActualData(GameJamData);
        }
        if (tab === "four") {
            setActualTab("four");
            setActualData(TalksAndWorkshops);
        }

    }

    return (
        <div className="profile-card" style={{ height: '100vh' }}>
            <div className="profile-header">
                <Header></Header>
                <div className="main-profile">
                    <div className="profile-image"></div>
                    <div className="profile-names">
                        <h1 className="username"> Javier</h1>
                        <h3>'Delunado'</h3>
                        <small className="page-title" style={{ marginTop: '6px' }}> Human Game Dev</small>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="colunm-left">
                    <div className="about-me">
                        <div>
                            <h2 className="about-me-tittle">
                                About me
                            </h2>
                            <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', marginTop: '15px' }}>
                                <p>
                                    Doing <b>horror indie games</b> at <a href="https://underthebedgames.com/" target="_blank">Under The Bed Games</a>
                                </p>
                                <p>
                                    Founder & <b>Game Jam Organizer</b> at <a href='https://twitter.com/spaingamedevs' target="_blank">Spain Game Devs</a>
                                </p>
                                <p>
                                    Staff & <b>Event Organizer</b> at <a href='https://malagajam.com/' target="_blank">MalagaJam/Guadalindie</a>
                                </p>
                                <p>
                                    Take a look to my <b>CV</b> <a href={CV} target="_blank">here! </a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <h2 className="about-me-tittle" style={{ marginTop: '2rem' }}>
                                Contact me
                            </h2>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '30px', paddingTop: '15px' }}>
                                <a href='https://delunado.itch.io/' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faItchIo} className="contact-icon" />
                                </a>
                                <a href='https://t.me/ladodelunado' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTelegram} className="contact-icon" />
                                </a>
                                <a href='https://twitter.com/delunad0' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitter} className="contact-icon" />
                                </a>
                                <a href='https://www.linkedin.com/in/javi-camacho/' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} className="contact-icon" />
                                </a>
                                <a href='https://github.com/Delunado' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faGithub} className="contact-icon" />
                                </a>
                                <a href='mailto:hello@delunado.com' target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="colunm-right" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: 'auto' }}>
                        <Tabs
                            value={actualTab}
                            onChange={(event, newValue) => changeTab(newValue)}
                            aria-label="secondary tabs example"
                            className='tab-header'
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <Tab
                                value="one"
                                label="All"
                                className="tab-style"
                                sx={{
                                    color: '#f3f3f3',
                                    width: '25%',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                }}
                            />
                            <Tab
                                value="two"
                                label="Commercial Games"
                                className="tab-style"
                                sx={{
                                    color: '#f3f3f3',
                                    width: '25%',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                }}
                            />
                            <Tab
                                value="three"
                                label="Game Jams & Experiments"
                                className="tab-style"
                                sx={{
                                    color: '#f3f3f3',
                                    width: '25%',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                }}
                            />
                            <Tab
                                value="four"
                                label="Talks & Events"
                                className="tab-style"
                                sx={{
                                    color: '#f3f3f3',
                                    width: '25%',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                }}
                            />
                        </Tabs>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '100%' }}>
                            <div className="card-container">
                                {actualData.map((cardData, index) => (
                                    <Card key={index} {...cardData} />
                                ))}
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </div >
    );
}

export default ProfileCard;