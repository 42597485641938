import './App.css';
import Card from './Components/Card/card';
import ProfileCard from './Components/ProfileCard';

function App() {
  return (
    <>
      <ProfileCard></ProfileCard>
    </>
  );
}

export default App;
